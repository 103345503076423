import React, { Fragment } from "react";
import "../assets/Loading.css";

function Loading() {
  return (
    <Fragment>
      <div className="dashboard-container">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </Fragment>
  );
}

export default Loading;
