import React, { useState, useEffect } from "react";
import Pagination from "./Pagination";
import ConfirmationModal from "./ConfirmationModal";
import { API_ROUTES } from "../Global/Endpoints";
import "../assets/Projects.css";
import axios from "axios";
import Loading from "../Common/Loading";
import DataTable from "./DataTable";
import { useAuth } from "../ContextApi/AuthContext";
import { toast } from "react-toastify";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const { getAccessToken, logout } = useAuth();
  const projectsPerPage = 5;
  const tableHeaderKeys = [
    "Project Name",
    "Email",
    "Created By",
    "Container Count",
    "Status",
  ];
  const dataKeys = [
    "projectName",
    "emailId",
    "createdBy",
    "containerCount",
    "status",
  ];

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleProjectDelete = (projectData) => {
    setProjectToDelete(projectData);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    let token = getAccessToken();
    const headers = {
      AccessToken: token,
    };
    try {
      await axios.delete(API_ROUTES.DELETE_PROJECT, {
        data: projectToDelete,
        headers: headers,
      });
      toast.success("Project deleted successfully!", {
        position: "bottom-right",
      });
      fetchProjects();
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Session expired. Please login again!", {
          position: "bottom-right",
        });
      } else {
        console.log("Error occurred: " + error);
      }
    } finally {
      setShowModal(false);
      setProjectToDelete(null);
    }
  };

  const fetchProjects = async () => {
    let token = getAccessToken();
    const headers = {
      AccessToken: token,
    };
    try {
      await axios
        .get(API_ROUTES.GET_PROJECTS, { headers: headers })
        .then((resp) => {
          if (resp) {
            setProjects(resp.data);
            setIsLoading(false);
          }
        });
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Session expired. Please login again!", {
          position: "bottom-right",
        });
      } else {
        console.log("Error occurred: " + error);
      }
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="content">
        <div className="title-wrapper">
          <h1 className="page-title">Project Management System</h1>
        </div>
        <div className="table-wrapper">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <DataTable
                dataList={projects}
                currentPage={currentPage}
                deleteData={handleProjectDelete}
                headerKeys={tableHeaderKeys}
                dataKeys={dataKeys}
                actionType="delete"
              />
              <Pagination
                className="pagination-container"
                totalPages={Math.ceil(projects.length / projectsPerPage)}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </div>
      </div>
      <ConfirmationModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={confirmDelete}
        message={`Are you sure you want to delete the project "${projectToDelete?.projectName}"?`}
      />
    </>
  );
};

export default Projects;
