import "./App.css";
import RouteConfig from "./Routes/RouteConfig";
import { AuthProvider } from "./ContextApi/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import  Sidebar from './Components/Sidebar'
// import  Header from './Components/Header'
// import  AddProject from './Components/AddProject'

function App() {
  return (
    <>
      <RouteConfig />
      <ToastContainer />
    </>
  );
}

export default App;
