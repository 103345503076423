// RouteConfig.js
import React from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AddProject from "../Components/AddProject";
import ContainerDetails from "../Components/ContainerDetails";
import Login from "../Components/Login";
import Projects from "../Components/Projects";
import PrivateRoute from "../Routes/PrivateRoute";
import AddNextcloud from "../Components/AddNextcloud";
import { AuthProvider } from "../ContextApi/AuthContext";
import UsersList from "../Components/UsersList";
import ChangePassword from "../Components/ChangePassword";
import SetupPassword from "../Components/SetupPassword";
import ForgotPassword from "../Components/ForgotPassword";

const RouteConfig = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/setup-password" element={<SetupPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route element={<PrivateRoute />}>
            <Route path="/addproject" element={<AddProject />} />
            <Route path="/addcontainer" element={<ContainerDetails />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/addnextcloud" element={<AddNextcloud />} />
            <Route path="/userslist" element={<UsersList />} />
            <Route path="change-password" element={<ChangePassword />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default RouteConfig;
