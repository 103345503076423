import { Navigate, Outlet } from "react-router-dom";
import Header from "../Components/Header";
import { useAuth } from "../ContextApi/AuthContext";
import Sidebar from "../Components/Sidebar";
import React, { useEffect } from "react";
import Footer from "../Components/Footer";
import "../assets/styles.css";
import { Constant_Strings } from "../Global/Endpoints";

const PrivateRoute = () => {
  const { authenticated } = useAuth();
  return authenticated ? (
    <div className="container">
      <Sidebar />
      <div className="right-wrapper">
        <Header />
        <marquee
          scrollamount="5"
          onMouseOver={(e) => e.target.stop()}
          onMouseOut={(e) => e.target.start()}
        >
          {Constant_Strings.marqueeString}
        </marquee>
        <Outlet />
        <Footer />
      </div>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
