import React from "react";
import "../assets/ConfirmationModal.css"; // Import CSS for styling

const ConfirmationModal = ({ show, onClose, onConfirm, message }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="backdrop">
      <div className="modal">
        <h2 className="modal-text">Confirmation</h2>
        <p className="modal-text">{message}</p>
        <div className="modal-buttons">
          <button className="btn btn-cancel" onClick={onClose}>
            Cancel
          </button>
          <button className="btn btn-confirm" onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
