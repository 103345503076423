import React, { useEffect, useRef, useState } from "react";
import "../assets/styles.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../ContextApi/AuthContext";
import logouticon from "../assets/Images/logout.png";
import usericon from "../assets/Images/user.png";
import changepasswordicon from "../assets/Images/changepassword.png";

function Header() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const modalRef = useRef(null);
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setUserName(userData.Username); // assuming the user data object has a "name" property
      setUserRole("Admin"); // assuming the user data object has a "role" property
    }
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChangePassword = () => {
    setIsModalOpen(!isModalOpen);
    navigate("/change-password");
  };

  return (
    <div className="header">
      <div className="loggedin-person-wrapper">
        <span className="profile-name">
          {userName} <span className="profile-role">{userRole}</span>
        </span>
        <div
          className="profile-pic"
          onClick={() => setIsModalOpen(!isModalOpen)}
        >
          <img src={usericon} alt="Profile" className="profile-pic-img" />
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay-profile">
          <div className="modal-overlay-content" ref={modalRef}>
            <div className="modal-content-item" onClick={handleChangePassword}>
              <span>
                <img
                  src={changepasswordicon}
                  alt="Change Password"
                  className="modal-content-icon"
                />
              </span>
              Change Password
            </div>
            <div className="modal-content-item" onClick={() => logout()}>
              <span>
                <img
                  src={logouticon}
                  className="modal-content-icon"
                  alt="Logout"
                />
              </span>
              Logout
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
