import React, { useState } from "react";
import "../assets/Pagination.css";
const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const [selectedPage, setSelectedPage] = useState(currentPage);

  const handlePageChange = (page) => {
    setSelectedPage(page);
    onPageChange(page);
  };

  const handleNext = () => {
    if (selectedPage < totalPages) {
      handlePageChange(selectedPage + 1);
    }
  };

  const handleBack = () => {
    if (selectedPage > 1) {
      handlePageChange(selectedPage - 1);
    }
  };

  const handleFirst = () => {
    handlePageChange(1);
  };

  const handleLast = () => {
    handlePageChange(totalPages);
  };

  return (
    <div className="pagination-container">
      <div className="pagination-row">
        <button
          className="btn-pagination"
          onClick={handleFirst}
          disabled={selectedPage === 1}
        >
          First
        </button>
        <button
          className="btn-pagination"
          onClick={handleBack}
          disabled={selectedPage === 1}
        >
          Back
        </button>
        <select
          className="pagination-select"
          value={selectedPage}
          onChange={(e) => handlePageChange(parseInt(e.target.value))}
        >
          {[...Array(totalPages).keys()].map((page) => (
            <option key={page + 1} value={page + 1}>
              {page + 1}
            </option>
          ))}
        </select>
        <button
          className="btn-pagination"
          onClick={handleNext}
          disabled={selectedPage === totalPages}
        >
          Next
        </button>
        <button
          className="btn-pagination"
          onClick={handleLast}
          disabled={selectedPage === totalPages}
        >
          Last
        </button>
      </div>
    </div>
  );
};

export default Pagination;
