import React from "react";
import logo5 from "../assets/Images/Trash1.png";

function CloseConfirmationModal({ onClose, isOpen, onConfirm }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="close-button" title="Close" onClick={onClose}>
          &times;
        </div>
        <div className="logo-wrapper1">
          <img src={logo5} alt="trash" className="logo1" />
        </div>
        <p>Are you sure you want to discard the changes?</p>
        <div className="modal-actions1 button">
          <button className="btn-primary1" onClick={onConfirm}>
            Yes
          </button>
          <button className="btn-primary1" onClick={onClose}>
            No
          </button>
        </div>
      </div>
    </div>
  );
}
export default CloseConfirmationModal;
