// const API_BASE_URL = "https://localhost:7294/api/";
const API_BASE_URL = "http://api.dockerxpo.com/api/";

export const Constant_Strings = {
  marqueeString:
    "Note: Ensure you create a subdomain (i.e., a preferred URL) pointing to 111.93.3.140 IP before initiating or adding a project!",
};

export const API_ROUTES = {
  //Project Routes
  INSERT_PROJECT: API_BASE_URL + "Project/InsertProject",
  GET_PROJECTS: API_BASE_URL + "Project/GetProjects",
  DELETE_PROJECT: API_BASE_URL + "Project/DeleteProject",
  INSERT_NEXTCLOUD_PROJECT: API_BASE_URL + "Project/InsertNextCloudProject",

  //User Routes
  GET_ALL_USERS: API_BASE_URL + "User/GetUsers",
  UPDATE_USER_STATUS: API_BASE_URL + "User/UpdateUserStatus",
  ADD_USER: API_BASE_URL + "User/AddUser",
  CHANGE_PASSWORD: API_BASE_URL + "User/ChangePassword",

  //Setup Password Routes
  VALIDATE_TOKEN_EXPIRY: API_BASE_URL + "Password/ValidateTokenExpiry?token=",
  UPDATE_PASSWORD: API_BASE_URL + "Password/UpdatePassword",
  FORGOT_PASSWORD: API_BASE_URL + "Password/ForgotPassword?emailId=",

  //Login & Logout routes
  USER_LOGIN: API_BASE_URL + "Security/GetAccessToken",
  USER_LOGOUT: API_BASE_URL + "Security/LogOut",
};
