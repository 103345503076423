import React from "react";
import { useNavigate } from "react-router-dom";
import "../assets/styles.css";
import logo1 from "../assets/Images/DockerXpo-Logo.png";
import logo2 from "../assets/Images/add-project.png";
import logo3 from "../assets/Images/projects.png";
import nextcloudicon from "../assets/Images/nextcloud-icon.png";
import usersicon from "../assets/Images/3-users.png";

function Sidebar() {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const handleAddProjectClick = () => {
    const result = navigate("/addproject");
  };

  return (
    <div className="left-sidebar">
      <div className="logo-wrapper">
        <img src={logo1} alt="Logo" className="logo" />
      </div>
      <div className="menu-wrap">
        {/* Use onClick to call handleAddProjectClick for programmatic navigation */}
        <div className="menu-item" onClick={handleAddProjectClick}>
          <span>
            <img src={logo2} alt="Add Project" />
          </span>
          Add Project
        </div>
        <div className="menu-item" onClick={() => navigate("/addnextcloud")}>
          <span>
            <img
              className="sidebar-icon"
              src={nextcloudicon}
              alt="Add Nextcloud"
            />
          </span>
          Add Nextcloud
        </div>
        {/* Use onClick to call handleProjectsClick for programmatic navigation */}
        <div className="menu-item" onClick={() => navigate("/projects")}>
          <span>
            <img src={logo3} alt="Projects" />
          </span>
          Projects
        </div>
        <div className="menu-item" onClick={() => navigate("/userslist")}>
          <span>
            <img className="sidebar-icon" src={usersicon} alt="Users List" />
          </span>
          Users
        </div>
      </div>
      <div className="copyright-wrap">
        <div className="copyright-wrap-text">{`@${currentYear} copyright`}</div>
      </div>
    </div>
  );
}

export default Sidebar;
