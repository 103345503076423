import React from "react";
import sesatlogo from "../assets/Images/logo.png";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-right">
        <span className="footer-text">Powered by: </span>
        <img className="footer-logo" alt="Sesat Solutions" src={sesatlogo} />
      </div>
    </div>
  );
}

export default Footer;
