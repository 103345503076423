import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/styles.css";

const AddProject = () => {
  const [projectDetails, setProjectDetails] = useState({
    projectName: "",
    fullName: "",
    email: "",
    containerCount: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    projectName: "",
    fullName: "",
    email: "",
    containerCount: "",
  });

  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim(); // Trim the input value
    setProjectDetails((prevDetails) => ({
      ...prevDetails,
      [name]: trimmedValue,
    }));

    validateField(name, trimmedValue);
  };
  const validateField = (name, value) => {
    const errors = { ...errorMessages };

    switch (name) {
      case "projectName":
        if (value.length < 3) {
          errors.projectName =
            "Project Name should contain at least 3 characters";
        } else if (value.length > 20) {
          errors.projectName =
            "Project Name should contain below 20 characters";
        } else if (!/^[a-zA-Z0-9 ]+$/.test(value)) {
          errors.projectName =
            "Project Name should not allow special characters";
        } else {
          errors.projectName = "";
        }
        break;
      case "fullName":
        if (value.length < 3) {
          errors.fullName = "Full Name should contain at least 3 characters";
        } else if (value.length > 20) {
          errors.fullName = "Full Name should contain below 20 characters";
        } else if (!/^[a-zA-Z ]+$/.test(value)) {
          errors.fullName = "Full Name should not allow special characters";
        } else {
          errors.fullName = "";
        }
        break;
      case "email":
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          errors.email = "Incorrect Email Format";
        } else {
          errors.email = "";
        }
        break;
      case "containerCount":
        if (value === "") {
          errors.containerCount = "Please enter container count";
        } else if (!/^\d+$/.test(value)) {
          errors.containerCount =
            "Container Count should only consist of integers";
        } else if (value.startsWith("0") && value.length > 1) {
          errors.containerCount =
            "Container Count should not have leading zeros";
        } else if (parseInt(value, 10) === 0) {
          errors.containerCount = "Container Count should be greater than zero";
        } else {
          errors.containerCount = "";
        }
        break;
      default:
        break;
    }

    setErrorMessages(errors);
    //updateNextButtonState();
  };

  useEffect(() => {
    updateNextButtonState();
  }, [projectDetails, errorMessages]);

  const updateNextButtonState = () => {
    const isDisabled =
      Object.values(errorMessages).some((error) => error !== "") ||
      Object.values(projectDetails).some((field) => field === "");

    setIsNextButtonDisabled(isDisabled);
  };

  const handleNextClick = () => {
    if (isNextButtonDisabled) {
      setFormErrorMessage("All fields are required");
      return;
    }

    // Clear the form error message
    setFormErrorMessage("");

    // Navigate to the Container Details page with projectDetails in state
    navigate("/addcontainer", { state: { projectDetails } });
  };

  return (
    <div className="content">
      <div className="title-wrapper">
        <h1 className="page-title">Add Project</h1>
      </div>
      <div className="form-wrapper">
        <div className="form-row">
          <label className="form-label">Project Name</label>
          <div className="form-group">
            <input
              type="text"
              className="form-field"
              name="projectName"
              value={projectDetails.projectName}
              onChange={handleChange}
              onBlur={() =>
                validateField("projectName", projectDetails.projectName)
              }
            />
            {errorMessages.projectName && (
              <div className="error-message red-color">
                {errorMessages.projectName}
              </div>
            )}
          </div>
        </div>

        <div className="form-row">
          <label className="form-label">Full Name</label>
          <div className="form-group">
            <input
              type="text"
              className="form-field"
              name="fullName"
              value={projectDetails.fullName}
              onChange={handleChange}
              onBlur={() => validateField("fullName", projectDetails.fullName)}
            />
            {errorMessages.fullName && (
              <div className="error-message red-color">
                {errorMessages.fullName}
              </div>
            )}
          </div>
        </div>

        <div className="form-row">
          <label className="form-label">Email Id</label>
          <div className="form-group">
            <input
              type="text"
              className="form-field"
              name="email"
              value={projectDetails.email}
              onChange={handleChange}
              onBlur={() => validateField("email", projectDetails.email)}
            />
            {errorMessages.email && (
              <div className="error-message red-color">
                {errorMessages.email}
              </div>
            )}
          </div>
        </div>

        <div className="form-row">
          <label className="form-label">Container Count</label>
          <div className="form-group">
            <input
              type="text"
              className="form-field"
              name="containerCount"
              value={projectDetails.containerCount}
              onChange={handleChange}
              onBlur={() =>
                validateField("containerCount", projectDetails.containerCount)
              }
            />
            {errorMessages.containerCount && (
              <div className="error-message red-color">
                {errorMessages.containerCount}
              </div>
            )}
          </div>
        </div>

        {formErrorMessage && (
          <div className="form-error-message red-color">{formErrorMessage}</div>
        )}

        <div className="form-row">
          <label className="form-label">&nbsp;</label>
          <div className="form-group">
            <button
              className="btn-primary"
              onClick={handleNextClick}
              disabled={isNextButtonDisabled}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProject;
