import React, { useEffect, useState } from "react";
import { useAuth } from "../ContextApi/AuthContext";
import logo from "../assets/Images/DockerXpo-Logo.png";
import showPasswordIcon from "../assets/Images/show-password.png";
import hidePasswordIcon from "../assets/Images/hide-password.png";
import axios from "axios";
import { API_ROUTES } from "../Global/Endpoints";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { authenticated, login, saveUserCredentials, clearUserCredentials } =
    useAuth();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);

  useEffect(() => {
    if (authenticated) {
      navigate("/projects");
    }
    let usercreds = localStorage.getItem("UserCredentials");
    const data = JSON.parse(usercreds);
    if (data != null) {
      setEmail(data.emailId);
      setPassword(data.password);
      setIsRememberMeChecked(true);
    }
  }, [authenticated, navigate]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!/\S+@\S+\.\S+/.test(e.target.value)) {
      setEmailError("Incorrect email format");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (e.target.value) {
      setPasswordError("");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handle_Login = async (e) => {
    e.preventDefault();

    if (!email) {
      setEmailError("Please enter email");
    }
    if (!password) {
      setPasswordError("Please enter password");
    }
    if (!email || !password || emailError) {
      return;
    }
    try {
      const response = await axios.get(API_ROUTES.USER_LOGIN, {
        params: {
          email: email,
          password: password,
        },
      });
      const { userId, accessToken, userName } = response.data;
      const userData = {
        UserId: userId,
        Token: accessToken,
        Username: userName,
      };
      if (userData.Token) {
        login(userData);
        toast.success("Login success!", {
          position: "bottom-right",
        });
        const usercreds = {
          emailId: email,
          password: password,
        };

        //If remember me is checked
        if (isRememberMeChecked) {
          saveUserCredentials(usercreds);
        } else {
          clearUserCredentials();
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setPasswordError(error.response.data.message);
        toast.error("Login failed!", {
          position: "bottom-right",
        });
      } else {
        setPasswordError("An error occurred. Please try again later.");
        toast.error("An error occurred. Please try again later.", {
          position: "bottom-right",
        });
      }
    }
  };

  return (
    <div className="login-page-bg">
      <div className="login-wrapper">
        <div className="login-logo-wrapper">
          <img src={logo} alt="" className="login-logo" />
        </div>
        <div className="login-logo-line"></div>

        <div className="login-form-wrapper">
          <h2 className="login-page-heading">Login</h2>
          <form onSubmit={handle_Login}>
            <div className="login-form-row">
              <label>Email</label>
              <div>
                <input
                  type="email"
                  className="login-textbox"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
                {emailError && (
                  <div className="login-error-message">{emailError}</div>
                )}
              </div>
            </div>

            <div className="login-form-row">
              <label>Password</label>
              <div className="password-wrapper">
                <input
                  type={showPassword ? "text" : "password"}
                  className="login-textbox"
                  placeholder="Password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <span
                  className="toggle-icon"
                  onClick={togglePasswordVisibility}
                >
                  <img
                    src={showPassword ? showPasswordIcon : hidePasswordIcon}
                    alt="Toggle Password Visibility"
                  />
                </span>
                {passwordError && (
                  <div className="login-error-message">{passwordError}</div>
                )}
              </div>
            </div>
            <div
              className="login-form-row remember-me-wrap"
              onClick={() => setIsRememberMeChecked(!isRememberMeChecked)}
            >
              <input
                className="remember-me"
                type="checkbox"
                checked={isRememberMeChecked}
              />
              <span className="remember-me-text">Remember me</span>
            </div>
            <button type="submit" className="login-button">
              Login
            </button>
          </form>
          <div className="forgot-password">
            <a onClick={() => navigate("/forgot-password")}>Forgot Password?</a>
          </div>
        </div>
      </div>
      <div className="login-footer">{`@${currentYear} copyright`}</div>
    </div>
  );
};

export default Login;
