import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_ROUTES } from "../Global/Endpoints";
import { useAuth } from "../ContextApi/AuthContext";
import CloseConfirmationModal from "./CloseConfirmationModal";
import showPasswordIcon from "../assets/Images/show-password.png";
import hidePasswordIcon from "../assets/Images/hide-password.png";
import FocusTrap from "focus-trap-react";

const ContainerDetails = () => {
  const [containerDetails, setContainerDetails] = useState({
    imageType: "",
    imageName: "",
    containerPort: "",
    preferredURL: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    imageType: "",
    imageName: "",
    containerPort: "",
    preferredURL: "",
  });
  const [showHidePassword, setShowHidePassword] = useState("hide");
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [containerCount, setContainerCount] = useState(1);
  const [projectDetails, setProjectDetails] = useState({
    projectName: "",
    clientFullName: "",
    emailId: "",
    containerCount: 0,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dockerHubCredentials, setDockerHubCredentials] = useState({
    username: "",
    emailId: "",
    password: "",
  });
  const [dockerHubCredentialError, setDockerHubCredentialError] = useState({
    username: "",
    emailId: "",
    password: "",
  });
  const [initialDockerHubCredentials, setInitialDockerHubCredentials] =
    useState(dockerHubCredentials);
  const [initialImageType, setInitialImageType] = useState("");
  const [allContainerDetails, setAllContainerDetails] = useState([]);
  const { getAccessToken, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOkDisabled, setIsOkDisabled] = useState(true);
  const [isConfirmPopupEnabled, setIsConfirmPopupEnabled] = useState(false);

  const handleModalCancelClick = () => {
    let initialDockerHubCreds = { ...initialDockerHubCredentials };
    const areModalValuesUpdated =
      JSON.stringify(initialDockerHubCreds) !==
      JSON.stringify(dockerHubCredentials);

    if (areModalValuesUpdated) {
      setIsConfirmPopupEnabled(true);
    } else if (
      Object.values(initialDockerHubCreds).every((field) => field !== "")
    ) {
      setIsModalOpen(false);
      setContainerDetails((prevDetails) => ({
        ...prevDetails,
        imageType: prevDetails.imageType,
      }));
    } else {
      setIsModalOpen(false);
      setContainerDetails((prevDetails) => ({
        ...prevDetails,
        imageType: initialImageType,
      }));
      setDockerHubCredentialError({
        username: "",
        emailId: "",
        password: "",
      });
      setIsNextButtonDisabled((prev) => {
        if (initialImageType === "") {
          return true;
        } else {
          return prev;
        }
      });
    }
  };

  const handleConfirmPopupConfirmClick = () => {
    setDockerHubCredentials(initialDockerHubCredentials);
    setIsConfirmPopupEnabled(false);
    setIsModalOpen(false);
    setContainerDetails((prevDetails) => ({
      ...prevDetails,
      imageType: initialImageType,
    }));
    setDockerHubCredentialError({
      username: "",
      emailId: "",
      password: "",
    });
  };

  useEffect(() => {
    updatePopupButtonState();
  }, [dockerHubCredentials, dockerHubCredentialError]);

  const updatePopupButtonState = () => {
    let updatedDockerHubCreds = { ...dockerHubCredentials };
    const isPopupDataValid =
      Object.values(updatedDockerHubCreds).every((field) => field !== "") &&
      Object.values(dockerHubCredentialError).every((error) => error === "");
    if (isPopupDataValid) {
      const isValid =
        JSON.stringify(initialDockerHubCredentials) !==
        JSON.stringify(updatedDockerHubCreds);
      setIsOkDisabled(!isValid);
    } else {
      setIsOkDisabled(true);
    }
  };

  const updateCredentials = (e) => {
    const { name, value } = e.target;
    setDockerHubCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
    validateField(name, value);
  };

  const handleModalOkClick = () => {
    let isPrivateImageFormDirty = Object.values(dockerHubCredentials).every(
      (field) => field !== ""
    );

    if (
      !isPrivateImageFormDirty ||
      Object.values(dockerHubCredentialError).some((error) => error !== "")
    ) {
      setDockerHubCredentialError((prevErrors) => ({
        ...prevErrors,
        username:
          dockerHubCredentials.username === ""
            ? "Please enter docker registry username"
            : prevErrors.username,
        emailId:
          dockerHubCredentials.emailId === ""
            ? "Please enter docker registry emailId"
            : prevErrors.emailId,
        password:
          dockerHubCredentials.password === ""
            ? "Please enter docker registry password"
            : prevErrors.password,
      }));
    } else {
      setIsModalOpen(false);
      setInitialDockerHubCredentials(dockerHubCredentials);
    }
  };

  useEffect(() => {
    updateNextButtonState();
  }, [isNextButtonDisabled, errorMessages, containerCount, initialImageType]);

  useEffect(() => {
    const projectDetailsFromLocation = location.state?.projectDetails;
    if (projectDetailsFromLocation) {
      setProjectDetails({
        clientFullName: projectDetailsFromLocation.fullName,
        projectName: projectDetailsFromLocation.projectName,
        emailId: projectDetailsFromLocation.email,
        containerCount: projectDetailsFromLocation.containerCount,
      });
    }
  }, [location.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "imageType" && value == 2) {
      setIsModalOpen(true);
      updateOkButtonState();
    }
    const trimmedValue = value.trim(); // Trim the input value and lowercase it

    if (value == "1" || value == "") {
      setInitialImageType(value);
      setDockerHubCredentials({
        username: "",
        emailId: "",
        password: "",
      });
      setInitialDockerHubCredentials({
        username: "",
        emailId: "",
        password: "",
      });
    }

    if (name === "imageType" && trimmedValue === "Select Image Type") {
      setContainerDetails((prevDetails) => ({
        ...prevDetails,
        [name]: "",
      }));
    } else {
      setContainerDetails((prevDetails) => ({
        ...prevDetails,
        [name]: trimmedValue,
      }));
      validateField(name, trimmedValue);
    }
  };

  const updateOkButtonState = () => {
    const isValid = Object.values(dockerHubCredentials).every(
      (field) => field !== ""
    );
    setIsOkDisabled(!isValid);
  };

  const validateField = (name, value) => {
    const trimmedValue = value.trim();
    const errors = { ...errorMessages };
    const dockerHubErrors = { ...dockerHubCredentialError };

    switch (name) {
      case "imageType":
        errors.imageType = trimmedValue === "" ? "Image Type is required" : "";
        break;
      case "imageName":
        errors.imageName =
          trimmedValue.length < 3
            ? "Image Name should contain at least 3 characters"
            : trimmedValue.length > 100
            ? "Image Name should contain below 100 characters"
            : "";
        break;
      case "containerPort":
        errors.containerPort = /^\d+$/.test(trimmedValue)
          ? ""
          : "Container Port should only consist of integers";
        break;
      case "preferredURL":
        // Add validation if needed
        break;
      case "username":
        dockerHubErrors.username =
          value === "" ? "Please enter docker registry username" : "";
        break;
      case "emailId":
        if (value === "") {
          dockerHubErrors.emailId = "Please enter docker registry emailId";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          dockerHubErrors.emailId = "Incorrect email format";
        } else {
          dockerHubErrors.emailId = "";
        }
        break;
      case "password":
        dockerHubErrors.password =
          value === "" ? "Please enter docker registry password" : "";
        break;
      default:
        break;
    }

    setErrorMessages(errors);
    setDockerHubCredentialError(dockerHubErrors);
    updateNextButtonState();
  };

  const updateNextButtonState = () => {
    const isDisabled =
      Object.values(errorMessages).some((error) => error !== "") ||
      Object.values(containerDetails).some((field) => field === "");
    setIsNextButtonDisabled(isDisabled);
  };

  const handleNextClick = () => {
    // Save current container details
    const updatedAllContainerDetails = [...allContainerDetails];
    updatedAllContainerDetails[containerCount - 1] = {
      ...containerDetails,
      projectId: 0,
      credentials:
        containerDetails.imageType === "2"
          ? [
              {
                username: dockerHubCredentials.username,
                emailId: dockerHubCredentials.emailId,
                password: dockerHubCredentials.password,
              },
            ]
          : [],
    };

    setAllContainerDetails(updatedAllContainerDetails);
    setContainerCount((prevCount) => prevCount + 1);
    clearData(); // Clears form container details form data

    // Below methods to set container details for next/back navigation
    setContainerDetails(
      updatedAllContainerDetails[containerCount] || {
        imageType: "",
        imageName: "",
        containerPort: "",
        preferredURL: "",
      }
    );
    // setInitialImageType("");
    setInitialImageType(
      updatedAllContainerDetails[containerCount]?.imageType || ""
    );
    setDockerHubCredentials(() => {
      const credentials = updatedAllContainerDetails[containerCount]
        ?.credentials[0] || {
        username: "",
        emailId: "",
        password: "",
      };
      return credentials;
    });

    setInitialDockerHubCredentials(() => {
      const credentials = updatedAllContainerDetails[containerCount]
        ?.credentials[0] || {
        username: "",
        emailId: "",
        password: "",
      };
      return credentials;
    });
  };

  const handleBackClick = () => {
    // Displaying previous container details
    const prevCount = containerCount - 1;
    if (prevCount > 0) {
      const data = allContainerDetails[prevCount - 1];
      setContainerDetails({
        imageType: data.imageType,
        imageName: data.imageName,
        containerPort: data.containerPort,
        preferredURL: data.preferredURL,
      });
      setInitialImageType(data.imageType);

      // Checking if previous container details had private image details and setting state
      const previousDockerHubCreds = (data.credentials &&
        data.credentials[0]) || {
        username: "",
        emailId: "",
        password: "",
      };

      setDockerHubCredentials(previousDockerHubCreds);
      setInitialDockerHubCredentials(previousDockerHubCreds);
      setContainerCount(prevCount);
      setErrorMessages({
        imageType: "",
        imageName: "",
        containerPort: "",
        preferredURL: "",
      });
      updateNextButtonState();
    }
  };

  const handleCancel = () => {
    navigate("/addproject");
  };

  const clearData = () => {
    setContainerDetails({
      imageType: "",
      imageName: "",
      containerPort: "",
      preferredURL: "",
    });
    setDockerHubCredentials({
      username: "",
      emailId: "",
      password: "",
    });
  };

  const handleProcessClick = () => {
    const updatedProjectDetails = {
      projects: [
        {
          ...projectDetails,
          createdBy: projectDetails.emailId,
          createdDate: new Date().toISOString(),
          status: "Pending",
          appImages: [
            ...(allContainerDetails || []),
            {
              imageName: containerDetails.imageName.toLowerCase(),
              containerPort: parseInt(containerDetails.containerPort),
              preferredUrl: containerDetails.preferredURL.toLowerCase(),
              imageType: containerDetails.imageType,
              projectId: 0,
              credentials:
                containerDetails.imageType === "2"
                  ? [
                      {
                        username: dockerHubCredentials.username,
                        emailId: dockerHubCredentials.emailId,
                        password: dockerHubCredentials.password,
                      },
                    ]
                  : [],
            },
          ],
        },
      ],
    };
    setProjectDetails(updatedProjectDetails);
    const token = getAccessToken();
    let requestHeader = {
      AccessToken: token,
    };

    axios
      .post(API_ROUTES.INSERT_PROJECT, updatedProjectDetails, {
        headers: requestHeader,
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Project inserted successfully!", {
            position: "bottom-right",
          });
          navigate("/");
        } else {
          toast.error("Failed to insert project. Please try again.", {
            position: "bottom-right",
          });
          navigate("/");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          logout();
          toast.error("Session expired. Please login again!", {
            position: "bottom-right",
          });
        } else if (error.response.status === 409) {
          toast.error(error.response.data, {
            position: "bottom-right",
          });
        }
        navigate("/");
      });
  };

  return (
    <div className="content">
      <div className="title-wrapper">
        <h1 className="page-title">Container Details</h1>
      </div>
      <div className="form-wrapper">
        <div className="close-button" title="Close" onClick={handleCancel}>
          &times;
        </div>
        <div className="form-row">
          <label className="form-label">Type of Image</label>
          <div className="form-group">
            <select
              className="form-field"
              name="imageType"
              onChange={handleChange}
              value={containerDetails.imageType}
            >
              <option value="">Select Image Type</option>
              <option value={2}>Private</option>
              <option value={1}>Public</option>
            </select>
            {errorMessages.imageType && (
              <div className="error-message red-color">
                {errorMessages.imageType}
              </div>
            )}
            {containerDetails.imageType === "2" && (
              <span
                className="edit-details-link"
                onClick={() => {
                  setIsModalOpen(true);
                  setIsOkDisabled(true);
                }}
              >
                Edit image details
              </span>
            )}
          </div>
        </div>

        {/* Modal for private images */}
        {/* Added FocusTrap to avoid input focus on parent form inputs when Modal is Open */}
        {isModalOpen && containerDetails.imageType === "2" && (
          <FocusTrap>
            <div className="backdrop">
              <div className="modal">
                <div className="modal-content">
                  <h2>DockerHub Credentials</h2>
                  <div className="modal-content-row">
                    <label>Username:</label>
                    <input
                      type="text"
                      name="username"
                      placeholder="Enter Docker Registry Username"
                      value={dockerHubCredentials.username}
                      onChange={updateCredentials}
                      onBlur={() =>
                        validateField("username", dockerHubCredentials.username)
                      }
                      autoComplete="off"
                      autoFocus={false}
                    />
                    {dockerHubCredentialError.username && (
                      <div className="error-message">
                        {dockerHubCredentialError.username}
                      </div>
                    )}
                  </div>
                  <div className="modal-content-row">
                    <label>Email:</label>
                    <input
                      type="text"
                      name="emailId"
                      placeholder="Enter Docker Registry Email"
                      value={dockerHubCredentials.emailId}
                      onChange={updateCredentials}
                      onBlur={() =>
                        validateField("emailId", dockerHubCredentials.emailId)
                      }
                      autoComplete="off"
                    />
                    {dockerHubCredentialError.emailId && (
                      <div className="error-message">
                        {dockerHubCredentialError.emailId}
                      </div>
                    )}
                  </div>
                  <div className="modal-content-row">
                    <label>Password:</label>
                    <div className="confirm-password-wrapper">
                      <input
                        type={showHidePassword === "hide" ? "password" : "text"}
                        name="password"
                        placeholder="Enter Docker Registry Password"
                        value={dockerHubCredentials.password}
                        onChange={updateCredentials}
                        onBlur={() =>
                          validateField(
                            "password",
                            dockerHubCredentials.password
                          )
                        }
                        autoComplete="new-password"
                      />
                      <span
                        className="password-toggle-icon"
                        onClick={() => {
                          setShowHidePassword((prev) => {
                            if (prev === "show") {
                              return "hide";
                            } else {
                              return "show";
                            }
                          });
                        }}
                      >
                        <img
                          src={
                            showHidePassword === "show"
                              ? showPasswordIcon
                              : hidePasswordIcon
                          }
                          alt="Toggle Password Visibility"
                        />
                      </span>
                    </div>
                    {dockerHubCredentialError.password && (
                      <div className="error-message">
                        {dockerHubCredentialError.password}
                      </div>
                    )}
                  </div>
                  <div className="modal-content-buttons-wrap">
                    <button
                      onClick={handleModalOkClick}
                      disabled={isOkDisabled}
                    >
                      OK
                    </button>
                    <button onClick={handleModalCancelClick}>Cancel</button>
                  </div>
                </div>
              </div>
              <CloseConfirmationModal
                isOpen={isConfirmPopupEnabled}
                onClose={() => setIsConfirmPopupEnabled(false)}
                onConfirm={handleConfirmPopupConfirmClick}
              />
            </div>
          </FocusTrap>
        )}

        <div className="form-row">
          <label className="form-label">Image Name</label>
          <div className="form-group">
            <input
              type="text"
              className="form-field"
              name="imageName"
              value={containerDetails.imageName}
              onChange={handleChange}
              onBlur={() =>
                validateField("imageName", containerDetails.imageName)
              }
            />
            {errorMessages.imageName && (
              <div className="error-message red-color">
                {errorMessages.imageName}
              </div>
            )}
          </div>
        </div>

        <div className="form-row">
          <label className="form-label">Container Port</label>
          <div className="form-group">
            <input
              type="text"
              className="form-field"
              name="containerPort"
              value={containerDetails.containerPort}
              onChange={handleChange}
              onBlur={() =>
                validateField("containerPort", containerDetails.containerPort)
              }
            />
            {errorMessages.containerPort && (
              <div className="error-message red-color">
                {errorMessages.containerPort}
              </div>
            )}
          </div>
        </div>

        <div className="form-row">
          <label className="form-label">Preferred URL</label>
          <div className="form-group">
            <input
              type="text"
              className="form-field"
              name="preferredURL"
              value={containerDetails.preferredURL}
              onChange={handleChange}
              onBlur={() =>
                validateField("preferredURL", containerDetails.preferredURL)
              }
            />
            <span>Example: test.sesatsolutions.com</span>
            {errorMessages.preferredURL && (
              <div className="error-message red-color">
                {errorMessages.preferredURL}
              </div>
            )}
          </div>
        </div>

        <div className="form-row">
          <label className="form-label">&nbsp;</label>
          <div className="form-group">
            <div className="buttons-container">
              <button
                className="btn-back"
                disabled={containerCount === 1}
                onClick={handleBackClick}
              >
                Back
              </button>
              <button
                className="btn-primary"
                onClick={
                  containerCount == projectDetails.containerCount
                    ? handleProcessClick
                    : handleNextClick
                }
                disabled={isNextButtonDisabled}
              >
                {containerCount == projectDetails.containerCount
                  ? "Process"
                  : "Next"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerDetails;
