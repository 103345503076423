import React, { useState, useEffect } from "react";
import CloseConfirmationModal from "./CloseConfirmationModal";

function AddUserModal({ isOpen, onClose, onSave }) {
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    organization: "",
  });

  const [errors, setErrors] = useState({
    userName: "",
    email: "",
    organization: "",
  });

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false); // State for the confirmation modal

  useEffect(() => {
    if (isOpen) {
      // Reset form data and errors when the modal is opened
      setFormData({
        userName: "",
        email: "",
        organization: "",
      });
      setErrors({
        userName: "",
        email: "",
        organization: "",
      });
      setIsSaveDisabled(true);
    }
  }, [isOpen]);

  useEffect(() => {
    // Check if all fields are valid
    const isFormValid =
      Object.values(errors).every((error) => error === "") &&
      formData.userName !== "" &&
      formData.email !== "" &&
      formData.organization !== "";

    setIsSaveDisabled(!isFormValid);
  }, [errors, formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "userName":
        if (value.length < 3) {
          error = "UserName should contain at least 3 characters.";
        } else if (value.length > 50) {
          error = "UserName should contain below 50 characters.";
        }
        break;
      case "email":
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          error = "Incorrect Email Format.";
        }
        break;
      case "organization":
        if (value.length < 5) {
          error = "Organization should contain at least 5 characters.";
        } else if (value.length > 20) {
          error = "Organization should contain below 20 characters.";
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleSubmit = () => {
    if (!isSaveDisabled) {
      onSave(formData);
      onClose();
    }
  };

  const handleCancel = () => {
    if (formData.userName || formData.email || formData.organization) {
      setIsConfirmationOpen(true); // Open the confirmation modal only if there's unsaved data
    } else {
      onClose(); // Close the modal if there's no unsaved data
    }
  };

  const handleConfirmCancel = () => {
    setFormData({
      userName: "",
      email: "",
      organization: "",
    });
    setErrors({
      userName: "",
      email: "",
      organization: "",
    });
    setIsSaveDisabled(true);
    setIsConfirmationOpen(false); // Close the confirmation modal
    onClose(); //Close the usermodal
  };

  return (
    <>
      {isOpen && ( // Render the modal only when it's open
        <div className="backdrop">
          <div className="modal-content">
            <div className="modal-header">
              <div
                className="close-button"
                title="Close"
                onClick={handleCancel}
              >
                &times;
              </div>
            </div>
            <h2 className="title">Create User</h2>
            <div className="form-group">
              <div className="input-container">
                <i className="fas fa-user input-icon"></i>
                <input
                  type="text"
                  name="userName"
                  placeholder="Username"
                  value={formData.userName}
                  onChange={handleChange}
                />
              </div>
              {errors.userName && (
                <span className="error-message">{errors.userName}</span>
              )}
            </div>
            <div className="form-group">
              <div className="input-container">
                <i className="fas fa-envelope input-icon"></i>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              {errors.email && (
                <span className="error-message">{errors.email}</span>
              )}
            </div>
            <div className="form-group">
              <div className="input-container">
                <i className="fas fa-building input-icon"></i>
                <input
                  type="text"
                  name="organization"
                  placeholder="Organization"
                  value={formData.organization}
                  onChange={handleChange}
                />
              </div>
              {errors.organization && (
                <span className="error-message">{errors.organization}</span>
              )}
            </div>
            <div className="modal-actions">
              <button
                onClick={handleSubmit}
                disabled={isSaveDisabled}
                className={isSaveDisabled ? "" : "btn-pagination"}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}
      <CloseConfirmationModal // Render the confirmation modal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={handleConfirmCancel}
      />
    </>
  );
}

export default AddUserModal;
