// AuthContext.js
import axios from "axios";
import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_ROUTES } from "../Global/Endpoints";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [authenticated, setAuthenticated] = useState(() => {
    let token = localStorage.getItem("userData");
    const userdata = JSON.parse(token);
    return userdata == null ? false : true;
  });

  const login = (userdata) => {
    setAuthenticated(true);
    localStorage.setItem("userData", JSON.stringify(userdata));
    navigate("/projects");
  };

  const logout = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData"));
      if (!userData) {
        console.error("No user data found in localStorage.");
      } else {
        const { UserId, Token } = userData;

        await axios.post(API_ROUTES.USER_LOGOUT, {
          userId: Number(UserId),
          accessToken: Token,
        });
      }
      setAuthenticated(false);
      localStorage.removeItem("userData");
      navigate("/");
    } catch (error) {
      console.error(
        "An error occurred during logout:",
        error.response || error.message || error
      );
      navigate("/");
    }
  };

  const getAccessToken = () => {
    let token = localStorage.getItem("userData");
    const userdata = JSON.parse(token);
    return userdata != null ? userdata.Token : "";
  };

  const saveUserCredentials = (usercreds) => {
    localStorage.setItem("UserCredentials", JSON.stringify(usercreds));
  };

  const clearUserCredentials = () => {
    localStorage.removeItem("UserCredentials");
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        login,
        logout,
        getAccessToken,
        saveUserCredentials,
        clearUserCredentials,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
