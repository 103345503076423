import { useEffect, useState } from "react";
import "../assets/ChangePassword.css";
import "../assets/styles.css";
import { API_ROUTES } from "../Global/Endpoints";
import { toast } from "react-toastify";
import axios from "axios";
import { useAuth } from "../ContextApi/AuthContext";
import hidepassword from "../assets/Images/hide-password.png";
import showpassword from "../assets/Images/show-password.png";

function ChangePassword() {
  const [passwordDetails, setPasswordDetails] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordDetailErrors, setPasswordDetailErrors] = useState({
    currentPasswordError: "",
    newPasswordError: "",
    confirmPasswordError: "",
  });
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);
  const [isChangePasswordDisabled, setIsChangePasswordDisabled] =
    useState(true);
  const [userId, setUserId] = useState("");
  const { getAccessToken, logout } = useAuth();
  const [showHidePassword, setShowHidePassword] = useState("hide");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    if (name !== "currentPassword") {
      validatePasswords(name, value);
    }
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData != null) {
      setUserId(userData.UserId);
    }
  }, []);

  const validatePasswords = (name, value) => {
    let errors = { ...passwordDetailErrors };

    if (name === "newPassword") {
      const isValid =
        value < 8 ||
        !/[a-z]/.test(value) ||
        !/[A-Z]/.test(value) ||
        !/[0-9]/.test(value) ||
        !/[!@#$%^&*]/.test(value) ||
        /\s/.test(value);
      errors.newPasswordError = isValid ? "Password is invalid!" : "";

      if (passwordDetails.currentPassword === value) {
        errors.newPasswordError =
          "Current password and New password cannot be same";
      }
    }

    if (name === "confirmPassword") {
      errors.confirmPasswordError =
        value === passwordDetails.newPassword ? "" : "Passwords do not match";
    }
    setPasswordDetailErrors(errors);
  };

  useEffect(() => {
    const isValid =
      Object.values(passwordDetails).every((field) => field !== "") &&
      Object.values(passwordDetailErrors).every((error) => error === "");
    setIsChangePasswordDisabled(!isValid);
  }, [passwordDetails, passwordDetailErrors]);

  const handleCancel = () => {
    setPasswordDetails({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setPasswordDetailErrors({
      currentPasswordError: "",
      newPasswordError: "",
      confirmPasswordError: "",
    });
    setIsChangePasswordDisabled(true);
  };

  const handlePasswordVisiblity = () => {
    setShowHidePassword((prev) => {
      if (prev === "hide") {
        return "show";
      } else {
        return "hide";
      }
    });
  };

  const handleChangePassword = () => {
    const token = getAccessToken();
    let requestHeader = {
      AccessToken: token,
    };
    const changePassword = {
      userId: userId,
      currentPassword: passwordDetails.currentPassword,
      newPassword: passwordDetails.newPassword,
    };
    try {
      axios
        .post(API_ROUTES.CHANGE_PASSWORD, changePassword, {
          headers: requestHeader,
        })
        .then((resp) => {
          if (resp.status === 200) {
            toast.success("Password updated successfully!", {
              position: "bottom-right",
            });
            handleCancel();
          } else {
            toast.error("Failed to update Password. Please try again!", {
              position: "bottom-right",
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            logout();
            toast.error("Session expired. Please login again!", {
              position: "bottom-right",
            });
          } else {
            toast.error(error.response.data, {
              position: "bottom-right",
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="content">
      <div className="title-wrapper">
        <h1 className="page-title">Change Password</h1>
      </div>
      <div className="change-password-form">
        <div className="form-section">
          <div className="form-section-row">
            <label className="form-section-label">Current Password</label>
            <div className="form-section-input">
              <input
                type="password"
                name="currentPassword"
                value={passwordDetails.currentPassword}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-section-row">
            <label className="form-section-label">New Password</label>
            <div className="form-section-input">
              <div className="form-input-row">
                <input
                  type="password"
                  name="newPassword"
                  value={passwordDetails.newPassword}
                  onChange={handleChange}
                />
                {passwordDetailErrors.newPasswordError && (
                  <span className="error-message">
                    {passwordDetailErrors.newPasswordError}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="form-section-row">
            <label className="form-section-label">Confirm Password</label>
            <div className="form-section-input">
              <div className="form-input-row">
                <div className="confirm-password-wrapper">
                  <input
                    type={showHidePassword === "hide" ? "password" : "text"}
                    name="confirmPassword"
                    value={passwordDetails.confirmPassword}
                    onChange={handleChange}
                  />
                  <span
                    onClick={handlePasswordVisiblity}
                    className="toggle-icon"
                  >
                    <img
                      src={
                        showHidePassword === "show"
                          ? showpassword
                          : hidepassword
                      }
                    />
                  </span>
                </div>
                {passwordDetailErrors.confirmPasswordError && (
                  <span className="error-message">
                    {passwordDetailErrors.confirmPasswordError}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="form-section-row">
            <div className="buttons-section">
              <button
                className="btn-primary"
                onClick={handleCancel}
                disabled={isCancelDisabled}
              >
                Cancel
              </button>
              <button
                className="btn-primary"
                onClick={handleChangePassword}
                disabled={isChangePasswordDisabled}
              >
                Change Password
              </button>
            </div>
          </div>
        </div>
        <div className="rules-section">
          <div className="password-form-rules">
            <ul className="validation-rules-list">
              <li className="rule">Length must be 8 characters</li>
              <li className="rule">
                Should have one lower case, one upper case, one number and one
                special character
              </li>
              <li className="rule">Should not contain spaces</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ChangePassword;
