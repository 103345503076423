import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API_ROUTES } from "../Global/Endpoints";
import "../assets/styles.css";
import logo from "../assets/Images/DockerXpo-Logo.png";
import Loading from "../Common/Loading";
import hidepassword from "../assets/Images/hide-password.png";
import showpassword from "../assets/Images/show-password.png";
import axios from "axios";
import { toast } from "react-toastify";

function SetupPassword() {
  const [tokenResponse, setTokenResponse] = useState({});
  const [passwordDetails, setPasswordDetails] = useState({
    password: "",
    confirmPassword: "",
  });
  const [passwordFormErrors, setPasswordFormErrors] = useState({
    password: "",
    confirmPassword: "",
  });
  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [showHidePassword, setShowHidePassword] = useState("hide");
  const [isSetupPasswordDisabled, setIsSetupPasswordDisabled] = useState(true);
  const currentYear = new Date().getFullYear();

  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get("token");

    if (token !== "") {
      setToken(token);
      validateToken(token);
    } else {
      setTokenResponse(null);
    }
  }, [location.search]);

  useEffect(() => {
    const isDisabled =
      Object.values(passwordDetails).every((field) => field != "") &&
      Object.values(passwordFormErrors).every((error) => error == "") &&
      passwordDetails.password === passwordDetails.confirmPassword;
    setIsSetupPasswordDisabled(!isDisabled);
  }, [passwordDetails, passwordFormErrors]);

  const validateToken = async (token) => {
    fetch(API_ROUTES.VALIDATE_TOKEN_EXPIRY + token)
      .then((response) => response.json())
      .then((data) => {
        setTokenResponse(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setTokenResponse(null);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
    validatePassword(name, value);
  };

  const validatePassword = (name, value) => {
    let errors = { ...passwordFormErrors };

    if (name === "password") {
      const passwordValid =
        value.length >= 8 &&
        /[a-z]/.test(value) &&
        /[A-Z]/.test(value) &&
        /\d/.test(value) &&
        /[^A-Za-z0-9]/.test(value) &&
        !/\s/.test(value);
      errors.password = passwordValid ? "" : "Password is invalid";
    }

    if (name === "confirmPassword") {
      errors.confirmPassword =
        value === passwordDetails.password ? "" : "Passwords do not match.";
    }

    setPasswordFormErrors(errors);
  };

  const handlePasswordVisiblity = () => {
    setShowHidePassword((prev) => {
      if (prev === "hide") {
        return "show";
      } else {
        return "hide";
      }
    });
  };

  const handleSetPasswordClick = () => {
    const updatePassword = {
      email: tokenResponse.emailId,
      password: passwordDetails.password,
      token: token,
    };

    axios.post(API_ROUTES.UPDATE_PASSWORD, updatePassword).then((resp) => {
      if (resp.status === 200) {
        toast.success("Password updated successfully!", {
          position: "bottom-right",
        });
        navigate("/");
      } else {
        toast.error("Failed to update Password. Please try again!", {
          position: "bottom-right",
        });
      }
    });
  };

  return (
    <div className="login-page-bg">
      <div className="login-wrapper">
        <div className="login-logo-wrapper">
          <img
            src={logo}
            alt=""
            className="login-logo"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="login-logo-line"></div>

        <div className="login-form-wrapper">
          <h2 className="login-page-heading">New Password Setup</h2>
          {isLoading ? (
            <Loading />
          ) : tokenResponse.isValid ? (
            <>
              <div className="password-form">
                <div className="password-form-row">
                  <label className="email-label">Email</label>
                  <label className="email-value">{tokenResponse.emailId}</label>
                </div>
                <div className="password-form-row">
                  <input
                    className="password-form-input"
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={passwordDetails.password}
                    onChange={handleChange}
                  />
                  {passwordFormErrors.password && (
                    <div className="error-message red-color">
                      {passwordFormErrors.password}
                    </div>
                  )}
                </div>
                <div className="password-form-row">
                  <div className="confirm-password-wrapper">
                    <input
                      className="password-form-input"
                      type={showHidePassword === "show" ? "text" : "password"}
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      value={passwordDetails.confirmPassword}
                      onChange={handleChange}
                    />
                    <span
                      onClick={handlePasswordVisiblity}
                      className="toggle-icon"
                    >
                      <img
                        src={
                          showHidePassword === "show"
                            ? showpassword
                            : hidepassword
                        }
                      />
                    </span>
                  </div>
                  {passwordFormErrors.confirmPassword && (
                    <div className="error-message red-color">
                      {passwordFormErrors.confirmPassword}
                    </div>
                  )}
                </div>
              </div>
              <div className="password-form-rules">
                <ul className="validation-rules-list">
                  <li>Length must be 8 characters</li>
                  <li>
                    Should have one lower case, one upper case, one number and
                    one special character
                  </li>
                  <li>Should not contain spaces</li>
                </ul>
              </div>
              <div className="password-setup-button">
                <button
                  className="set-password"
                  onClick={handleSetPasswordClick}
                  disabled={isSetupPasswordDisabled}
                >
                  Set Password
                </button>
              </div>
            </>
          ) : (
            <div className="token-invalid-form">
              <div>Link expired / not a valid link</div>
            </div>
          )}
        </div>
      </div>
      <div class="login-footer">{`@${currentYear} copyright`}</div>
    </div>
  );
}

export default SetupPassword;
