import React from "react";
import "../assets/DataTable.css";

const DataTable = ({
  dataList,
  currentPage,
  deleteData,
  headerKeys,
  dataKeys,
  actionType,
  handleAction,
}) => {
  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = dataList.slice(startIndex, endIndex);

  return (
    <div className="project-table">
      <table className="data-table">
        <thead>
          <tr>
            {headerKeys.map((key) => (
              <th key={key}>{key}</th>
            ))}
            <th style={{ textAlign: "center" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentData.map((data) => (
            <tr key={data["id"]}>
              {dataKeys.map((key) => (
                <td key={key}>{data[key]}</td>
              ))}
              <td>
                {actionType === "delete" ? (
                  <button className="delete" onClick={() => deleteData(data)}>
                    Delete
                  </button>
                ) : (
                  <button
                    className="delete"
                    onClick={() => handleAction(data)}
                  >
                    Action
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;

