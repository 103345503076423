import { useNavigate } from "react-router-dom";
import logo from "../assets/Images/DockerXpo-Logo.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_ROUTES } from "../Global/Endpoints";
import { useAuth } from "../ContextApi/AuthContext";
import tick from "../assets/Images/tick.png";
import Loading from "../Common/Loading";

function ForgotPassword() {
  const navigate = useNavigate();
  const [emailId, setEmailId] = useState("");
  const [isResetDisabled, setIsResetDisabled] = useState(true);
  const { getAccessToken } = useAuth();
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    validateEmail();
  }, [emailId]);

  const validateEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern.test(emailId)) {
      setIsResetDisabled(false);
    } else {
      setIsResetDisabled(true);
    }
  };

  const handleOnChange = (e) => {
    setEmailId(e.target.value);
  };

  const handleResetClick = () => {
    setIsLoading(true);
    const requestHeader = getAccessToken();
    try {
      axios
        .post(
          API_ROUTES.FORGOT_PASSWORD + emailId,
          {},
          { headers: requestHeader }
        )
        .then((resp) => {
          if (resp.status === 200) {
            setIsRequestSuccess(true);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log("Error: " + error);
          setIsRequestSuccess(false);
          setIsLoading(false);
        });
    } catch (error) {
      console.log("Error: " + error);
    }
  };

  return (
    <div className="login-page-bg">
      <div className="login-wrapper">
        <div className="login-logo-wrapper">
          <img
            src={logo}
            alt=""
            className="login-logo"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="login-logo-line"></div>

        <div className="login-form-wrapper">
          <h2 className="login-page-heading">Forgot Password</h2>
          {isLoading ? (
            <div className="loading-div">
              <Loading />
            </div>
          ) : isRequestSuccess ? (
            <div className="forgot-password-wrap">
              <div className="success-logo">
                <img src={tick} alt="Success" />
              </div>
              <div className="success-text">
                <p>A email has been sent to the specified email address..!</p>
              </div>
              <div className="login-text">
                <a onClick={() => navigate("/")}>Login</a>
              </div>
            </div>
          ) : (
            <form onSubmit={handleResetClick}>
              <div className="forgot-password-message">
                <p>
                  Enter your email address and we will send you a link to reset
                  your password
                </p>
              </div>
              <div className="email-field">
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  onChange={handleOnChange}
                />
              </div>
              <div className="reset-password-button">
                <button
                  className="btn-primary"
                  disabled={isResetDisabled}
                  onClick={handleResetClick}
                >
                  Reset Password
                </button>
              </div>
              <div className="login-text">
                <a onClick={() => navigate("/")}>Login</a>
              </div>
            </form>
          )}
        </div>
      </div>
      <div className="login-footer">{`@${currentYear} copyright`}</div>
    </div>
  );
}

export default ForgotPassword;
