import React, { useEffect, useState } from "react";
import { API_ROUTES } from "../Global/Endpoints";
import DataTable from "./DataTable";
import Pagination from "./Pagination";
import Loading from "../Common/Loading";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddUserModal from "./AddUserModal";
import ConfirmationModal from "./ConfirmationModal";
import { useAuth } from "../ContextApi/AuthContext";

function UsersList() {
  const [usersList, setUsersList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [userToToggle, setUserToToggle] = useState(null);
  const tableHeaderKeys = ["User Name", "EmailId", "Organization", "Status"];
  const dataKeys = ["userName", "email", "organization", "status"];
  const usersPerPage = 5;
  const { getAccessToken, logout } = useAuth();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    const token = getAccessToken();
    let requestHeader = {
      AccessToken: token,
    };
    axios
      .get(API_ROUTES.GET_ALL_USERS, { headers: requestHeader })
      .then((response) => {
        setUsersList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          logout();
          toast.error("Session expired. Please login again!", {
            position: "bottom-right",
          });
        } else {
          console.log("Error occurred: " + error);
        }
        setIsLoading(false);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSaveUser = (newUser) => {
    const token = getAccessToken();
    let requestHeader = {
      AccessToken: token,
    };
    axios
      .post(API_ROUTES.ADD_USER, newUser, { headers: requestHeader })
      .then((response) => {
        if (response.status === 200) {
          toast.success("User inserted successfully!", {
            position: "bottom-right",
          });
          fetchUsers();
        } else {
          toast.error("Failed to insert user. Please try again.", {
            position: "bottom-right",
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          logout();
          toast.error("Session expired. Please login again!", {
            position: "bottom-right",
          });
        } else if (error.response.status === 400) {
          toast.error("User already exists", {
            position: "bottom-right",
          });
        }
      });
  };

  const handleAction = (userData) => {
    setUserToToggle(userData);
    setShowConfirmModal(true);
  };

  const confirmToggleUserStatus = async () => {
    const token = getAccessToken();
    let requestHeader = {
      AccessToken: token,
    };
    const updatedStatus = userToToggle.status === "Active" ? "3" : "2";
    try {
      await axios.post(
        API_ROUTES.UPDATE_USER_STATUS,
        {
          ...userToToggle,
          status: updatedStatus,
        },
        {
          headers: requestHeader,
        }
      );
      fetchUsers();
      toast.success(`User status updated `, {
        position: "bottom-right",
      });
    } catch (error) {
      console.error("Error updating user status:", error);
      toast.error("Failed to update user status. Please try again.", {
        position: "bottom-right",
      });
    } finally {
      setShowConfirmModal(false);
      setUserToToggle(null);
    }
  };

  //below code will display bakend error message if existing email occured
  //     // .catch((error) => {
  //     //   console.error("Error making API call:", error);
  //     //   if (error.response && error.response.status === 400 && error.response.data.includes("Duplicate user found with Email")) {
  //     //     // Extract the email from the error message
  //     //     const errorMessage = error.response.data;
  //     //     toast.error(errorMessage, {
  //     //       position: "bottom-right",
  //     //     });
  //       } else {
  //         toast.error("An error occurred. Please try again.", {
  //           position: "bottom-right",
  //         });
  //       }
  //     });
  // };

  return (
    <div className="content">
      <div className="title-wrapper">
        <h1 className="page-title">Users List</h1>
        <button className="btn-primary" onClick={() => setIsModalOpen(true)}>
          Add User
        </button>
      </div>
      <div className="table-wrapper">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <DataTable
              dataList={usersList}
              currentPage={currentPage}
              headerKeys={tableHeaderKeys}
              dataKeys={dataKeys}
              actionType="toggle"
              handleAction={handleAction}
            />
            <Pagination
              className="pagination-container"
              totalPages={Math.ceil(usersList.length / usersPerPage)}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </>
        )}
        <AddUserModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={handleSaveUser}
        />
        <ConfirmationModal
          show={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={confirmToggleUserStatus}
          message={
            userToToggle?.status === "Active"
              ? "Are you sure you want to deactivate this user?"
              : "Are you sure you want to activate this user?"
          }
        />
      </div>
    </div>
  );
}

export default UsersList;
