import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/styles.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_ROUTES } from "../Global/Endpoints";
import { useAuth } from "../ContextApi/AuthContext";

const AddNextcloud = () => {
  const [projectDetails, setProjectDetails] = useState({
    clientFullName: "",
    emailId: "",
    preferredURL: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    clientFullName: "",
    emailId: "",
    preferredURL: "",
  });

  const { logout, getAccessToken } = useAuth();

  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const isDisabled =
      Object.values(errorMessages).some((error) => error !== "") ||
      Object.values(projectDetails).some((field) => field === "");

    setIsNextButtonDisabled(isDisabled);
  }, [errorMessages, projectDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim(); // Trim the input value
    setProjectDetails((prevDetails) => ({
      ...prevDetails,
      [name]: trimmedValue,
    }));

    validateField(name, trimmedValue);
  };

  const validateField = (name, value) => {
    const errors = { ...errorMessages };

    switch (name) {
      case "clientFullName":
        if (value.length < 3) {
          errors.clientFullName =
            "Full Name should contain at least 3 characters";
        } else if (value.length > 20) {
          errors.clientFullName =
            "Full Name should contain below 20 characters";
        } else if (!/^[a-zA-Z ]+$/.test(value)) {
          errors.clientFullName =
            "Full Name should not allow special characters";
        } else {
          errors.clientFullName = "";
        }
        break;
      case "emailId":
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          errors.emailId = "Incorrect Email Format";
        } else {
          errors.emailId = "";
        }
        break;
      case "preferredURL":
        // Add validation if needed
        errors.preferredURL = "";
        break;
      default:
        break;
    }
    setErrorMessages(errors);
  };

  const handleProcessClick = () => {
    if (isNextButtonDisabled) {
      setFormErrorMessage("All fields are required");
      return;
    }

    // Clear the form error message
    setFormErrorMessage("");

    const updatedProjectDetails = {
      projects: [
        {
          ...projectDetails,
          projectName: "NextCloud",
          createdBy: projectDetails.emailId,
          createdDate: Date.UTC,
          status: "Pending",
          projectId: 0,
          containerCount: 2,
          appImages: [
            {
              imageName: "nextcloud",
              containerPort: parseInt("80"),
              preferredUrl: projectDetails.preferredURL.toLowerCase(),
              imageType: "Public" === "Private" ? 2 : 1,
              projectId: 0,
              credentials: [],
            },
          ],
        },
      ],
    };
    const token = getAccessToken();
    let requestHeader = {
      AccessToken: token,
    };
    axios
      .post(API_ROUTES.INSERT_NEXTCLOUD_PROJECT, updatedProjectDetails, {
        headers: requestHeader,
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Project inserted successfully!", {
            position: "bottom-right",
          });

          navigate("/projects");
        } else {
          toast.error("Failed to insert project. Please try again.", {
            position: "bottom-right",
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          logout();
          toast.error("Session expired. Please login again!", {
            position: "bottom-right",
          });
        } else {
          console.log("Error occurred: " + error);
        }
      });
  };

  return (
    <div className="content">
      <div className="title-wrapper">
        <h1 className="page-title">Add Nextcloud</h1>
      </div>
      <div className="form-wrapper">
        <div className="form-row">
          <label className="form-label">Full Name</label>
          <div className="form-group">
            <input
              type="text"
              className="form-field"
              name="clientFullName"
              value={projectDetails.clientFullName}
              onChange={handleChange}
              onBlur={() =>
                validateField("clientFullName", projectDetails.clientFullName)
              }
            />
            {errorMessages.clientFullName && (
              <div className="error-message red-color">
                {errorMessages.clientFullName}
              </div>
            )}
          </div>
        </div>
        <div className="form-row">
          <label className="form-label">Email Id</label>
          <div className="form-group">
            <input
              type="text"
              className="form-field"
              name="emailId"
              value={projectDetails.emailId}
              onChange={handleChange}
              onBlur={() => validateField("email", projectDetails.emailId)}
            />
            {errorMessages.emailId && (
              <div className="error-message red-color">
                {errorMessages.emailId}
              </div>
            )}
          </div>
        </div>

        <div className="form-row">
          <label className="form-label">Preferred URL</label>
          <div className="form-group">
            <input
              type="text"
              className="form-field"
              name="preferredURL"
              value={projectDetails.preferredURL}
              onChange={handleChange}
              onBlur={() =>
                validateField("preferredURL", projectDetails.preferredURL)
              }
            />
            <span>Example: test.sesatsolutions.com</span>
            {errorMessages.preferredURL && (
              <div className="error-message red-color">
                {errorMessages.preferredURL}
              </div>
            )}
          </div>
        </div>

        {formErrorMessage && (
          <div className="form-error-message red-color">{formErrorMessage}</div>
        )}

        <div className="form-row">
          <label className="form-label">&nbsp;</label>
          <div className="form-group">
            <button
              className="btn-primary"
              onClick={handleProcessClick}
              disabled={isNextButtonDisabled}
            >
              Process
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNextcloud;
